/**
 * Application Router configuration
 */

import React, { Suspense } from 'react'
import {
    BrowserRouter as Router,
    Route,
    Link as RouteLink,
    Redirect,
    withRouter,
    Switch
} from 'react-router-dom'

import { makeStyles, createMuiTheme } from '@material-ui/core/styles'
import { ThemeProvider } from '@material-ui/styles';
import ProgressLoader from './components/ProgressLoader'

import routes from './LoginRoutes'
import AppStyles from './components/Typography/Typography'
const SignInForm = React.lazy(() => import('./components/Login/SignInForm'))

const drawerWidth = 240

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },

    content: {
        position: 'relative',
        flexGrow: 1,
    }

}))

const theme = createMuiTheme(AppStyles);

const Login = (props) => {
    const classes = useStyles(theme)
    console.log("test", window.location)


    
    return (
        <div className={classes.root}>
            <ThemeProvider theme={theme}>
                <Router>
                    <Suspense fallback={<ProgressLoader />}>
                        <main
                            className={classes.content} >
                                <Switch>
                                {window.location.pathname == "/" ?
                                <>
                                    <Route
                                            key={0}
                                            path='/'
                                            exact
                                            component={SignInForm} />
                                    {routes.map((route, index) => (
                                        <Route
                                        key={index + 1}
                                        exact
                                        path={route.path}
                                        component={route.component} />
                                        ))}
                                         <Redirect from="*" to="/" />
                                </>
                                    :
                                     <>
                                     <Route
                                            key={1}
                                            path='/nc'
                                            exact
                                            component={SignInForm} />
                                    {routes.map((route, index) => (
                                        <Route
                                        key={index + 1}
                                        exact
                                        path={route.path}
                                        component={route.component} />
                                        ))}
                                     </>
                                    }
                                </Switch>
                        </main>
                    </Suspense>
                </Router>
            </ThemeProvider>
        </div>
    )
}

export default Login
