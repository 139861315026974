import React, { useEffect, useContext } from "react";
import { I18nProvider, I18n } from "@lingui/react";
import { UserStateContext } from '../ContextAPI/UserContext';
const ContextProvider = (props) => {
    const [catalogs, setCatalogs] = React.useState([]);
    const [userState, userDispatch] = useContext(UserStateContext)
    const [locale, setLocale] = React.useState("en")

    useEffect(() => {
        if(userState.userData?.dashboard_language){
            setLocale(userState.userData?.dashboard_language)
            localStorage.setItem("nuovo-language", locale)
        }else{
            let local = localStorage.getItem("nuovo-language")
            if(local){
                setLocale(local)
            }
        }
    }, [userState])

   
    React.useEffect(() => {
        const loadCatalog = async (locale) => {
            const catalog = await import(
                `@lingui/loader!../../locales/${locale}/messages.po`
            );
            setCatalogs({
                ...catalogs,
                [locale]: catalog,
            });
        };
        loadCatalog(locale);
    }, [locale]);
    if (!catalogs[locale]) return <div />;
    return (
        <I18nProvider language={locale} catalogs={catalogs} >
            {props.children}
        </I18nProvider>
    );
};


export default ContextProvider
