import React, { createContext, useReducer, useContext } from 'react';


/* Action Types */
const SET_USER = 'SET_USER';
const UPDATE_USER = 'UPDATE_USER';
const SET_LANGUAGE = 'SET_LANGUAGE'

/* Define a context and a reducer for updating the context */
export const UserStateContext = createContext();

const initialState = {
    userData: {},
};

const userStateReducer = (state, action) => {
    switch (action.type) {
        case SET_USER:
            return {
                ...state,
                userData: { ...action.payload },
            };
        case UPDATE_USER:
            return {
                ...state,
                userData: { ...action.payload },
            };
        case SET_LANGUAGE:
            return {
                ...state,
                userData: { ...state.userData, dashboard_language: action.payload }
            };
        default:
            return state;
    }
};


/* Export a component to provide the context to its children. This is used in our _app.js file */

export const UserStateProvider = ({ children }) => {
    const [userState, userDispatch] = useReducer(
        userStateReducer,
        initialState
    );

    return (
        <UserStateContext.Provider value={[userState, userDispatch]}>
            {children}
        </UserStateContext.Provider>
    );
};
