import React from "react"

/**
 * React.lazy takes a function that must call a dynamic import(). 
 * This must return a Promise which resolves to a module with 
 * a default export containing a React component.
 * https://reactjs.org/docs/code-splitting.html
 */
const SignInForm = React.lazy(() => import('./components/Login/SignInForm'))
const SignUpForm = React.lazy(() => import('./components/Login/SignUpForm'))
const ResendConfirmEmailForm = React.lazy(() => import('./components/Login/ResendConfirmEmailForm'))
const ForgotPassword = React.lazy(() => import('./components/Login/ForgotPassword'))
const ChangePassword = React.lazy(() => import('./components/Login/ChangePassword'))
const GoogleAuthenticatorForm = React.lazy(() => import('./components/Login/GoogleAuthenticatorForm'))
const routes =  [
        {
            // path: 'users/sign_in',
            path: '/sign_in',
            component: SignInForm
        },
        {   
            path: '/sign_up',
            // path: '/sign_up',
            component: SignUpForm
        },
        {
            path: '/confirm',
            // path: '/confirm',
            component: ResendConfirmEmailForm
        },
        {   
            path: '/forgot-password',
            // path: '/forgot',
            component: ForgotPassword
        },
        {
            path: '/change-password',
            // path: '/forgot',
            component: ChangePassword
        },
        {   
            path: '/users/two_factor_authentication',
            component: GoogleAuthenticatorForm
        },
        {   
            // path: 'nc/sign_up',
             path: '/nc/sign_up',
            component: SignUpForm
        },
        {
            path: '/nc/forgot-password',
            // path:'/nc/forgot-password',
            component: ForgotPassword
        },
        {
            path: '/nc/change-password',
            // path: '/forgot',
            component: ChangePassword
        },

]

export default routes